<template>
  <v-row
    :style="`height: ${height}`"
    align="center"
    justify="center"
    class="pa-4 ma-0 center"
    :class="{
      'eva-dropzone': borderDots,
    }"
    @click="click"
  >
    <v-col>
      <v-icon
        x-large
        v-text="icon"
        v-if="icon"
      />
      <div>
        <span class="subtitle-2 text--primary">
          {{ title || "Glissez-déposez votre fichier Excel ici" }}
        </span>
        <div class="body-2 text--secondary">
          {{ subtitle || $t("documents.uploadClick") }}
        </div>
      </div>
      <slot name="actions" />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "DropzonePlaceholder",
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    icon: {
      type: [String, Boolean],
      default: "mdi-cloud-upload-outline",
    },
    borderDots: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  methods: {
    click() {
      this.$emit("click")
    },
  },
}
</script>

<style>
.eva-dropzone {
  border-radius: 5px;
  border: 2px dashed #c8c8c8 !important;
  cursor: pointer;
  user-select: none;
}
</style>

